
import './style/index.css';

function App() {
  return (
    <div className="container">
      <h1 className="main">chatapp-webapp</h1>
    </div>
  );
}

export default App;
